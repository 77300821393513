import { Injectable } from '@angular/core';
import {BranchesGQL, BranchGQL} from '../../generated/graphql';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BranchService {

  constructor(
    private branchesGQL: BranchesGQL,
    private branchGQL: BranchGQL
  ) { }

  getBranches() {
    return this.branchesGQL.watch().valueChanges;
  }

  getBranch(id: string) {
    return this.branchGQL.watch({
      where: {
        id: {
          eq: id
        }
      }
    }).valueChanges
  }
}
