import {Injectable} from '@angular/core';
import {
  DeploymentsByMonthGQL,
  EventType,
  MarkNotificationsAsReadGQL,
  MarkNotificationsAsReadInput,
  NotificationsGQL,
  OrderFilterInput,
  OrdersGQL,
  UpdateDeploymentGQL,
  UpdateDeploymentInput
} from '../../generated/graphql';
import {EventService} from '../event/event.service';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(
    private notificationsGQL: NotificationsGQL,
    private markNotificationGQL : MarkNotificationsAsReadGQL,
    private ordersGQL: OrdersGQL,
    private deploymentsByMonth: DeploymentsByMonthGQL,
    private updateDeploymentGQL: UpdateDeploymentGQL,
    private eventService: EventService
  ) { }

  getNotifications() {
    return this.notificationsGQL.watch(undefined,{
      // pollInterval: 3000,
    }).valueChanges;
  }

  getEventTypeIcon(type: EventType) {
    switch (type) {
      case EventType.Birthday:
        return 'pi pi-gift'
      default:
        return 'pi pi-gift'
    }
  }

  updateDeployment(input: UpdateDeploymentInput, eventId: string) {
    return this.updateDeploymentGQL.mutate({input},{
      refetchQueries: [
        {
          query: this.eventService.checkInListGQL.document,
          variables: {
            eventId: eventId
          }
        }
      ]
    })
  }

  getDeploymentsByMonth(employeeId: string, year: Date) {
    return this.deploymentsByMonth.watch({
      employeeID: employeeId,
      year,
    }).valueChanges
  }

  getOrders(where?: OrderFilterInput) {
    return this.ordersGQL.watch({
      where
    }).valueChanges
  }

  markNotification(input: MarkNotificationsAsReadInput){
    return this.markNotificationGQL.mutate({input} , {
      refetchQueries: [
        {
          query: this.notificationsGQL.document
        }
      ]
    })
  }
}
